@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
@import 'custom';

.navbarContainer {
    min-height: 150px;

    .navbarBrandContainer {
        div {
            border: none;
            &:focus {
                border: none;
            }
            img {
                width: 24px;
                height: 24px;
            }
        }
    }

    .navbarSearchContainer {
        position: absolute;
        top: 25%;
        right: 15px;

        .collapse:not(.show) {
            display: none;
        }

        .show {
            display: flex !important;
        }
    }

    .navbar-links {
        border-top: 1px solid #b32767;
        bottom: 0;
        a {
            color: #333333;
        }
    }
}

//TODO ADD BREAKPOINTS FOR OTHER SIZES
@include media-breakpoint-down(sm) {
    .navbarContainer {
        padding: 0;
        min-height: $navbar-height-sm;
        max-height: $navbar-height-sm - 20px;

        .navbar-links {
            background-color: $primary;
            z-index: 1000;
        }

        .navbarSearchContainer {
            top: 15px;
            right: 0;
        }

        .navbarBrandContainer {
            .navBrand {
                width: 156px;
                img {
                    height: 32px;
                }
            }
            .navbar-search {
                input,
                button {
                    height: 20px;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .navbarContainer {
        .container {
            max-width: 1500px;
        }
        max-height: $navbar-height-md;
        .navbarBrandContainer {
            .navBrand {
                width: auto;
                height: 69px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .navbarContainer {
        max-height: $navbar-height-md + 20px;
        .navbarSearchContainer {
            input {
                width: 250px;
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1400px) {
    .navbarContainer {
        flex-wrap: wrap !important;
        margin: 0 auto !important;
        z-index: 1000;

        .container {
            max-width: 1500px !important;
            padding: 0;
        }

        .navbar-controls {
            padding-right: 80px;
        }

        .navBrand {
            width: auto !important;
            padding-left: 80px;
        }
        .navbarMenu {
            display: block !important;
        }
        .collapse {
            display: none !important;
        }

        .show {
            display: flex !important;
        }

        .navbarSearchContainer {
            top: 35px;
            right: 80px;
        }

        .navbar-links {
            border-top: none;
            background-color: $primary;

            ul {
                flex-direction: column !important;
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .navbarContainer {
        z-index: 1000;
        .navbarSearchContainer {
            right: 80px;
            top: 28px;
        }
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .navbarContainer {
        z-index: 1000;
        .navbarSearchContainer {
            right: 80px;
            top: 20px;
        }
    }
}

@media screen and (min-width: 576px) and (max-width: 1400px) {
    .navbarContainer {
        height: 110px !important;
        min-height: 90px !important;
        .container {
            max-width: 1500px;
            padding-left: 0;
            padding-right: 0;
        }
        .navBrand {
            img {
                height: 48px !important;
            }
        }
        .navbar-links {
            border-top: none;
            background-color: $primary;
            max-width: 1500px;
            bottom: 0;
            padding-left: 100px;

            ul {
                border-top: 1px solid #b32767;
                width: 100%;
                flex-direction: column;
            }
        }
    }
}
