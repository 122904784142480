@import url('https://fonts.googleapis.com/css2?family=Kurale&display=swap');

@import 'custom';
@import 'bootstrap';

.font-lato-200 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}

.font-lato-400 {
    font-family: 'Lato', sans-serif;
    font-weight: 400 !important;
}

.font-lato-700 {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
}

.font-playfair-400 {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
}

.font-playfair-700 {
    font-family: 'Playfair Display', serif;
    font-weight: 700;
}

.normalize-nextjs-image {
    //Used to override NextJS Image absolute property
    position: relative !important;
}

.marker-orange {
    background-color: #fe850b;
}

.marker-pink {
    background-color: #fc7899;
}

.marker-dark-pink {
    background-color: #f3595e;
}

.marker-yellow {
    background-color: #fdfd77;
}

.marker-green {
    background-color: #62f962;
}

.marker-blue {
    background-color: #72ccfd;
}

.pen-orange {
    color: #fe850b;
    background-color: transparent;
}

.pen-light-red {
    color: #ed2129;
    background-color: transparent;
}

.pen-red {
    color: #e71313;
    background-color: transparent;
}

.pen-dark-red {
    color: #b80000;
    background-color: transparent;
}

.pen-green {
    color: #128a00;
    background-color: transparent;
}

.pen-yellow {
    color: #ffdf1b;
    background-color: transparent;
}

.ad-container-h600 {
    height: 650px;
}

.sticky-ad {
    position: sticky;
    z-index: 5;
    position: -webkit-sticky;
    top: $navbar-height-md;
}

.social-media-logos {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50%;
    border: 1px solid $border-color;
    padding: 6px;

    img {
        max-width: 20px;
        width: 100%;
        max-height: 20px;
        height: 100%;
        &:hover {
            opacity: 0.5;
        }
    }
}

.iframeContainer {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

//OneTrust button styling
#ot-sdk-btn.ot-sdk-show-settings {
    padding: 0 !important;
    border: 0 !important;
    font-family: 'Lato', sans-serif;
    font-weight: 400 !important;

    &:hover {
        text-decoration: none !important;
    }
}

//Ads
.ad-300 {
    width: 300px;
}

//React Bootstrap Pagination Control
.react-boostrap-pagination-control {
    li {
        a {
            color: #ab3f6e;
        }
    }
    .active {
        .page-link {
            color: #ab3f6e;
        }
    }
}

//Author labels everywhere
.author-label {
    color: #6d6464 !important;
    line-height: 105%;
}

@include media-breakpoint-down(sm) {
    .social-media-logos {
        max-width: 32px;
        max-height: 32px;
    }
    .sticky-ad {
        top: $navbar-height-sm;
    }
}

html,
body {
    padding: 0;
    margin: 0;
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        sans-serif;
    #__next {
        background-color: #fff;
    }
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
    html {
        color-scheme: dark;
    }
}

.website-container {
    background-color: #fff;
}

//TODO ADD BREAKPOINTS FOR OTHER SIZES
@include media-breakpoint-down(sm) {
    .website-container {
        padding-top: 20px;
    }
    .fixed-nav {
        padding-top: $navbar-height-sm;
    }
}

@include media-breakpoint-up(md) {
    .website-container {
        padding-top: 20px;
    }
    .fixed-nav {
        padding-top: $navbar-height-md;
    }
}
